import { render, staticRenderFns } from "./addsuggestions.vue?vue&type=template&id=94df78e2&scoped=true&"
import script from "./addsuggestions.vue?vue&type=script&lang=js&"
export * from "./addsuggestions.vue?vue&type=script&lang=js&"
import style0 from "./addsuggestions.vue?vue&type=style&index=0&id=94df78e2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94df78e2",
  null
  
)

export default component.exports