<template>
  <div class="addenterprise">
    <div class="store_table">
      <div class="store_table_item">
        <span>服务建议 </span>
      </div>
    </div>
    <div class="essential_information">
      <div class="form_essential">
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <div>
            <el-form-item label="封面图:" prop="images">
              <el-upload
                action=""
                :file-list="fileList"
                list-type="picture-card"
                :limit="5"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :http-request="uploadCategoryPic"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
            <span
              style="font-size: 14px; color: #6ba1ff; position: relative; left: 100px; top: -10px"
              >((建议图片整体尺寸为442*226 px，大小不超过8MB）)</span
            >

            <el-form-item label="服务建议:" prop="suggestion">
              <el-input v-model="form.suggestion" type="textarea"  maxlength="1000"
  show-word-limit placeholder="请输入渠道简介" />
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div style="margin-top: 30px" class="dialog-footer">
        <el-button type="primary" @click="submitForm" :disabled="disabled">保存</el-button>
        <el-button @click="clcans">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { addSuggestion, updateSuggestion, getOne } from '@/api/channel'

import { apiUpload } from '@/api/content'
export default {
  name: 'addsuggestions',
  computed: {
    id() {
      return this.$route.query.id
    }
  },
  data() {
    return {
      form: {
        coverImage: '',
        id: 0,
        images: [],
        suggestion: ''
      }, //表单收集
      fileList: [],
      param: {},

      rules: {
        images: [{ required: true, message: '请上传图片', trigger: 'blur' }],
        suggestion: [{ required: true, message: '请输入服务建议', trigger: 'blur' }]
      },
      disabled:false,//防止重复点击
    }
  },
  created() {
    if (this.id) {
      this.getOne()
    }
  },
  methods: {
    //详情
    async getOne() {
      const { data: res } = await getOne({ id: this.id })
      if (res.resultCode == 200) {
        this.form = res.data
        let imglist = this.form.images
                imglist.forEach((val) => {
                    this.fileList.push({
                        name: val,
                        url: val
                    })
                })
      }
    },
    //图片删除
    handleRemove(file, fileList) {
        this.form.images=[]
        fileList.forEach(el=>{
            this.form.images.push(el.url)
        })
       
      
    },
    handlePictureCardPreview(file) {
      console.log(file);
      this.form.images = file.url
      this.dialogVisible = true
    },
    async uploadCategoryPic(params) {
      const form = new FormData()

      form.append('file', params.file)

      const { data: res } = await apiUpload(form)

      this.form.images.push(res.data)
    },

    /** 提交按钮 */
    submitForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.disabled=true
          if (!this.form.id) {
            addSuggestion(this.form).then((response) => {
              if (response.data.resultCode == 200) {
                this.$message.success('新增成功')
                this.$router.go(-1)
                this.disabled=false
              } else {
                this.$message.error(response.data.message)
              }
            })
          } else {
            updateSuggestion(this.form).then((response) => {
              if (response.data.resultCode == 200) {
                this.$message.success('编辑成功')
                this.$router.go(-1)
                this.disabled=false
              } else {
                this.$message.error(response.data.message)
              }
            })
          }
        }
      })
    },
    clcans() {
      this.$router.push({
        path: '/channel/Servicesuggestions',
        query: {
          changeNum: true
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>

::v-deep .el-textarea {
    position: relative;
    display: inline-block;
    width: 596px;
    vertical-align: bottom;
    font-size: 14px;
}
::v-deep .el-textarea .el-input__count {
    color: #909399;
    background: #fff;
    position: absolute;
    font-size: 12px;
    bottom: 25px;
    right: 10px;
    height: 10px;
}
::v-deep .essential_information .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 596px;
  height: 100px;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

::v-deep .essential_information .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 596px;
}
::v-deep .cascsder_input .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 300px;
}
.addenterprise {
  width: 100%;
  background: #ffffff;
  padding: 10px 35px;
  .label_code {
    padding: 30px 0px;
  }
  .child_label {
    background: #f5f6f7;
    padding: 10px 15px;
    display: flex;
    align-items: baseline;
    width: 80%;
  }
  .store_table {
    height: 70px;
    box-sizing: border-box;
    margin: 0 72px 20px 0;
    padding-left: 10px;
    display: flex;

    .store_table_item {
      color: #4e93fb;
      margin-right: 50px;
      height: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 20px;
    }

    .active {
      color: #4e93fb;
      font-size: 20px;
      border-bottom: 2px solid #4e93fb;
      border-radius: 1px;
    }
  }

  .img_tips {
    position: relative;
    top: -10px;
    color: #6ba1ff;
  }

  .essential_information {
    h4 {
      color: #146aff;
    }
  }

  .label_management {
    padding: 30px 10px;

    .new_page {
      margin: 20px auto;
      width: 100%;
      text-align: center;
    }

    .input-with {
      width: 100%;
      overflow: hidden;
      padding: 20px 0px;

      .input-with-select {
        width: 400px;
        float: right;
      }

      .seach {
        background-color: #448aff;
        color: #fff;
        border-radius: 0;
        border: 1px solid #448aff;
      }
    }
  }
  .dialog-footer {
    border-top: 1px solid #f5f5f5;
    padding-top: 20px;
    display: flex;
    justify-content: center;
  }
}
</style>
